<template>
  <div class="whiteList">
    <div class="mainBox">
      <div>
        <el-card shadow="hover" class="mb10">
          <el-form v-model="filter" size="small" label-width="70px">
            <el-row
              :gutter="10"
              type="flex"
              justify="start"
              style="flex-wrap: wrap; flex-direction: row"
            >
              <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
                <el-form-item label="Date" label-width="60px" class="mb5">
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    align="right"
                    unlink-panels
                    :clearable="false"
                    range-separator="-"
                    start-placeholder="Start Date"
                    end-placeholder="End Date"
                    :picker-options="pickerOptions"
                    size="mini"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="Affiliate(s):" class="mb5">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                      <group-select
                        v-model="filter.affiliateIds"
                        :data-source="affiliatesGroupList"
                        class="w100"
                        multiple
                        :loading="affiliateLoading"
                        clearable
                        reserve-keyword
                        filterable
                        placeholder="Please select Affiliate"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="Interval:" class="mb5" label-width="80px">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item label="Month" class="mb0">
                        <el-checkbox v-model="filterCheckedStatus" label="month" class="color-white"
                          >.
                        </el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Date" class="mb0">
                        <el-checkbox v-model="filterCheckedStatus" class="color-white" label="date"
                          >.
                        </el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="24" align="right">
                <el-form-item label-width="0">
                  <el-button type="primary" icon="el-icon-download" @click="downloadFie"
                    >download
                  </el-button>
                  <el-button
                    type="primary"
                    icon="el-icon-search"
                    :loading="loading.list"
                    @click="getList(1)"
                    >Search
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <el-card shadow="hover">
          <el-table
            v-loading="loading.list"
            ref="tableRef"
            class="w100"
            height="70vmin"
            stripe
            border
            :data="list"
            size="mini"
            highlight-current-row
            @sort-change="sortChange"
          >
            <el-table-column label="date" prop="date" align="center" sortable="custom">
            </el-table-column>
            <el-table-column
              label="affiliateId"
              prop="affiliateId"
              align="center"
            ></el-table-column>
            <el-table-column
              label="affiliateName"
              prop="affiliateName"
              align="center"
            ></el-table-column>
            <el-table-column
              label="installs"
              prop="installs"
              align="center"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              label="events"
              prop="events"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="实际payout"
              prop="payout"
              align="center"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              label="理论payout"
              prop="theoryPayout"
              align="center"
              min-width="100"
              sortable="custom"
            ></el-table-column>

            <el-table-column
              label="差值"
              prop="revenue"
              align="center"
              min-width="100"
              sortable="custom"
            ></el-table-column>
          </el-table>
          <!--          <pagination
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageParam.pageSize"
            :currentPage="pageParam.page"
          ></pagination>-->
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { getDynamicAdjustReport } from '@/api/report';
  import { myBlockMixin } from '@/mixins/blockmixins.js';
  // import Pagination from '@/components/pagination';
  import { getPidsList } from 'api/product/rankSearch';
  import dateUtils from '@/utils/dateutils.js';
  import { filterObject } from 'utils/utils';
  import { mapState, mapActions } from 'vuex';
  import GroupSelect from '@/components/GroupSelect';
  import qs from 'querystring';

  export default {
    name: 'AffiliateAdjustReport',
    mixins: [myBlockMixin],
    components: {
      // Pagination,
      GroupSelect,
    },
    data() {
      return {
        option: {
          pidList: [],
          appIdList: [],
        },
        fullWidth: document.documentElement.clientWidth,
        list: [],
        loading: {
          list: false,
          dialog: false,
        },
        postData: {},
        pageParam: {
          page: 1,
          pageSize: 1000000,
          total: 0,
        },
        total: 0,
        appsList: [],
        allPidAppid: [],
        pidList: [],
        startToEndDate: '',
        filter: {
          columns: [],
        },
        // 筛选参数状态
        filterCheckedStatus: ['affiliate_id', 'date'],
      };
    },
    mounted() {
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(start);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getPids();
      this.getAffiliateList();
      this.getList();
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    methods: {
      // get Affiliate
      ...mapActions('affiliate', ['getAffiliateList']),
      calculateValue(row) {
        return ((row.theoryPayout || 0) - (row.payout || 0)).toFixed(4);
      },
      extracted: function() {
        const param = {
          ...this.pageParam,
        };

        if (this.filter.offerIds) {
          param.offerIds = this.filter.offerIds;
        }

        if (this.filter.affiliateIds) {
          param.affiliateList = this.filter.affiliateIds.toString();
        }
        param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();
        if (this.filter.sorting) {
          param.sorting = this.filter.sorting;
        }
        if (this.filter.sortType) {
          param.sortType = this.filter.sortType;
        }
        if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
          let startTime = this.startToEndDate[0];
          let endTime = this.startToEndDate[1];
          param.startTime = startTime + ' 00:00:00';
          param.endTime = endTime + ' 23:59:59';
        }
        return param;
      },
      // 获取数据列表
      getList(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        const param = this.extracted();
        getDynamicAdjustReport(param)
          .then((res) => {
            this.loading.list = true;
            this.total = res.total;
            this.list = res.result;
            this.loading.list = false;
          })
          .catch(() => {
            this.loading.list = false;
          });
      },
      getPids() {
        getPidsList().then((res) => {
          this.pidList = res.result;
        });
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getList();
      },
      // 下载excel表格
      downloadFie() {
        this.$confirm('最多下载10万条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            let param = this.extracted();
            param = filterObject(param);
            let strParam = qs.stringify(param);
            let url = '/api/report/dynamicAdjustReport/export?' + strParam;
            console.log(url);
            window.open(url, '_blank');
          })
          .catch((error) => {
            this.$message({
              type: 'info',
              message: error.message,
            });
          });
      },
      // 排序监听
      sortChange(column) {
        if (column.prop !== null && column.order !== null) {
          this.filter.sorting = column.prop;
          this.filter.sortType = column.order === 'ascending' ? 'asc' : 'desc';
        }
        this.handleCurrentChange(1);
      },
    },
  };
</script>
<style scoped>
  .table-row {
    height: 50px;
  }

  .row {
    display: flex;
  }

  /* .button-group {
display: flex;
justify-content: space-between;
align-items: left;
} */
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* 将 align-items 改为 flex-start，使按钮顶部对齐 */
    flex-wrap: wrap; /* 添加 flex-wrap 属性，使按钮换行显示 */
    width: 100%; /* 设置容器的宽度为100% */
  }

  .button-group .el-button {
    margin-bottom: 5px;
    margin-left: 1px;
  }

  .el-progress {
    width: 35%;
  }

  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
</style>
